.st0_spinner {
  fill: none;
  stroke-width: 12;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

svg._spinner {
  max-width: 300px;
  margin: 0 auto;
}

.feat_spinner {
  /* transform-origin: 108px 108px;
  transform: scale(0.75); */
}

@keyframes offset_one {
  42.8571% {
    opacity: 1;
    stroke-dashoffset: 0;
  }

  43.1428% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    stroke-dashoffset: 0;
  }
}

.sub_one_spinner path:nth-of-type(1) {
  stroke-dasharray: 585;
  stroke-dashoffset: 585;
  animation: offset_one 2s linear infinite;
}

@keyframes rotate {
  42.8571%,
  91.4285% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(390deg);
  }
}

.sub_one_spinner path:nth-of-type(2) {
  transform-origin: 108px 108px;
  transform: rotate(30deg);
  animation: rotate 2s linear infinite;
}

@keyframes offset_two {
  42.8571% {
    opacity: 0;
  }

  43.1428% {
    opacity: 1;
  }

  45.7142% {
    stroke-dashoffset: 0;
  }

  88.5714%,
  100% {
    opacity: 1;
    stroke-dashoffset: 585;
  }
}

.sub_one_spinner path:nth-of-type(3) {
  opacity: 0;
  stroke-dasharray: 585;
  stroke-dashoffset: 0;
  animation: offset_two 2s linear infinite;
}
